.Footer {
    height: 180px;
    width: 100%;
    background-color: rgb(40, 34, 34);
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
}

.socialLinks {
    display: flex;
    gap: 40px
}

.socialLinks ion-icon {
    font-size: 24px;
    cursor: pointer;
    margin-top: 24px;
}


.backContainer ion-icon {
    font-size: 30px;
    cursor: pointer;
}

.backContainer {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    cursor: pointer;
}

a {
    color: #fff;
}
