.Skills {
    background-color: #e7e4e4;
    padding-bottom: 100px;
}

.skillContainer {
    padding-left: 24%;
    padding-right: 24%;
}

.skillIcons_1 {
    display: flex;
    justify-content: space-between;
}

.skillIcons_2 {
    display: flex;
    justify-content: space-between;
}

.skillImage {
    width: auto;
    height: 85px;
}

.skill {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.fontClass {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
}

.fontClassLight {
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    margin-top: 17px;
}

@media(max-width: 924px) {
    .skillIcons_1 {
        display: flex;
        flex-direction: column;
        gap: 37px
    }

    .skillIcons_2 {
        display: flex;
        flex-direction: column;
        gap: 37px
    }

    .fontClass {
        text-align: center;
    }
}
