.About {
    background-color: #e7e4e4;
    padding-top: 100px;
    padding-bottom: 100px;
    padding-left: 10%;
    padding-right: 10%;
}

.aboutHeader {
    display: flex;
    justify-content: center;
}

.aboutStart {
    padding: 10px;
    border: solid 4px black;
    width: 20%;
    display: flex;
    justify-content: center;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    text-align: center;
}

.aboutInfo {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.explore {
    border-left: solid 2.5px black;
    border-right: solid 2.5px black;
    padding-left: 17px;
    padding-right: 17px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
}

.exploreContainer_1 {
    display: flex;
    justify-content: space-around;
    margin-top: 70px;
    margin-bottom: 40px;
}

.exploreContainer_2 {
    display: flex;
    justify-content: center;
}

.aboutBoxImage {
    position: absolute;
    width: 40px;
    opacity: 0.4;
}

.aboutBox {
    gap: 10px;
}

.aboutFont {
    margin-left: 24px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
}

@media(max-width: 924px) {
    .exploreContainer_1 {
        display: flex;
        flex-direction: column;
        gap: 40px;
    }
    
    .aboutStart {
        width: 80%;
    }
}