.navContainer {
    display: flex;
    justify-content: space-around;
}

.navLinks {
    display: flex;
    justify-content: space-between;
    color: #fff;

}

.navLink {
    list-style: none;
    padding: 20px;
    font-weight: bold;
    cursor: pointer;
}


.navLink:last-of-type {
    background-color: #fff;
    color: black;
    border-radius: 44px;
}

.logoContainer {
    display: flex;
    align-items: center;
}

.Navbar {
    position: absolute;
    width: 100%;
    z-index: 2;
}

.Home {
    background-color: #e7e4e4;
}

.mobileNavContainer {
    display: none;
}

.mobileMenu {
    display: none;
}


@media(max-width: 1218px) {
    .navLinks {
        margin-left: 100px;
    }

    .navContainer {
        justify-content: space-between;
        padding-left: 4%;
        padding-right: 4%;
    }

    .navLink {
        font-size: smaller;
    }
}


@media(max-width: 924px) {

    .navContainer {
        display: none;
    }

    .mobileNavContainer {
        display: flex;
        background-color: #1a1a1a;
        width: 100%;
        height: 80px;
        justify-content: flex-end;
        align-items: center;
    }

    .mobileNavContainer ion-icon {
        font-size: 40px;
        color: #fff;
        margin-right: 5%;
    }

    .mobileMenu {
        display: flex; 
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0; 
        left: 0;
        right: 0;
        background-color: #1a1a1a;
        overflow: hidden;
        transition: transform 0.3s ease;
        transform: translateY(-100%);
        height: 100vH;
    }
    
    .mobileMenu.open {
        transform: translateY(0);
    }

    .navLinks {
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        align-items: center;
        margin: 0;
        padding: 0;
        gap: 4rem
    }

    .mobileMenu > ion-icon {
        color: #fff;
        position: absolute;
        top: 0;
        right: 0;
        font-size: 40px;
        margin: 5%
    }
}