.Contact {
    padding-top: 100px;
    padding-bottom: 100px;
}

.contactInput {
    border: solid 4px black;
    padding: 10px;
    border-top: none;
    border-right: none;
    background-color: #e7e4e4;
}

.inputContainer {
    display: flex;
    flex-direction: column;
    padding-left: 25%;
    padding-right: 25%;
    gap: 40px
}

.contactText {
    border: solid 4px black;
    padding: 10px;
    border-top: none;
    border-right: none;
    background-color: #e7e4e4;
    height: 200px;
    resize: none;
}

@media(max-width: 924px) {
    .inputContainer {
        padding-left: 7%;
        padding-right: 7%;
    }

    .aboutInfo > p {
        text-align: center;
    }

    .aboutInfo {
        padding-left: 7%;
        padding-right: 7%;
    }
}