.left-side {
    width: 50%;
    height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.right-side {
    width: 50%;
    height: 100vh;
    background-color: black;
    transform-origin: center top;
    transform: skewX(-10deg); 
    justify-content: center;
    align-items: center;
    display: flex;
}

.split-layout {
    display: flex;
}

.right-side-container {
    width: 50%;
    height: 100vh;
    background-color: black;
    display: flex;
}

.profileImage {
    width: 500px;
    transform: skewX(10deg);
    position: absolute;
    margin-left: 400px;
    bottom: 25%;
    border-radius: 40px;
}

.left-sideContainer > h1, h2 {
    margin: 0;
    padding: 0;
}

.startName {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
}

.startIconContainer {
    display: flex;
    gap: 17px;
    margin-top: 70px;
    justify-content: flex-start;
}

.startIconContainer ion-icon {
    font-size: 34px;
    padding: 7px;
    background-color: rgb(215, 215, 215);
    box-shadow: inset 0 0 10px 0 black;
    border-radius: 7px;
    cursor: pointer;
}

.startIconContainer ion-icon:hover {
    opacity: 0.7;
}

.startNameMobile {
    display: none;
}

.profileImageMobile {
    display: none;
}

@media(max-width: 1118px) {
    .profileImage {
        width: 400px;
    }

    .right-sideContainer {
        padding-right: 4%;
    }
}

@media(max-width: 1028px) {
    
}

@media(max-width: 924px) {
    .right-side-container {
        display: none;
    }

    .right-side {
        display: none;
    }

    .startNameMobile {
        display: flex;
        font-family: 'Montserrat', sans-serif;
        font-weight: 300;
    }

    .startName {
        display: none;
    }

    .left-side {
        width: 100%;
    }

    .left-sideContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-right: 2%;
        padding-left: 2%;
        height: 100vH;
        padding-top: 80px;
    }

    .profileImageMobile {
        display: flex;
        width: 100%;
        border-radius: 10px;
        margin-top: 4%;
        max-width: 570px;
    }

    .Start {
        padding-bottom: 10%;
    }

    .startIconContainer {
        margin-top: 30px;
    }
}